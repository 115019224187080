import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AutosizeDirective, ScrollTopDirective} from './_base/layout';
import {
  FirstLetterPipe,  JoinPipe, SafePipe, TimeElapsedPipe, FilterPipe, SortPipe,
  FormValidationRegExService, RegexService,
} from './_base/layout';

@NgModule({
  imports: [CommonModule],
  declarations: [
    // directives
    ScrollTopDirective,
    // pipes
    TimeElapsedPipe,
    JoinPipe,
    SafePipe,
    FirstLetterPipe,
    FilterPipe,
    SortPipe,
    AutosizeDirective,
  ],
  exports: [
    // directives
    ScrollTopDirective,
    // pipes
    TimeElapsedPipe,
    JoinPipe,
    SafePipe,
    FirstLetterPipe,
    FilterPipe,
    SortPipe,
    AutosizeDirective,
  ],
  providers: [
    FormValidationRegExService, RegexService,
  ]
})
export class CoreModule {}
