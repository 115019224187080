import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {ToastService} from "../../../core/services/toast/toast.service";
import {ToastEvent} from "../../../core/services/toast/models/toast.event";

@Component({
  selector: 'kt-toaster',
  templateUrl: './toaster.component.html',
  styleUrls: ['./toaster.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ToasterComponent implements OnInit {
    toasts: ToastEvent[] = [
    ];

    constructor(private toastService: ToastService, private cdr: ChangeDetectorRef) {}

    subscribeToToasts() {
        this.toastService.toastEvents.subscribe((toast) => {
            this.toasts.push(toast);
            this.cdr.detectChanges();
        });
    }

    ngOnInit() {
        this.subscribeToToasts();
    }

    remove(index: number) {
        this.toasts = this.toasts.filter((v, i) => i !== index);
        this.cdr.detectChanges();
    }

}
