import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { PERFECT_SCROLLBAR_CONFIG, PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { TranslateModule } from '@ngx-translate/core';
import { InlineSVGModule } from 'ng-inline-svg';
import { CoreModule } from './core/core.module';
import { SplashScreenService } from './core/_base/layout';
import { reducers, metaReducers } from './core/reducers';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { PartialsModule } from './views/partials/partials.module';
import { CookieService } from 'ngx-cookie-service';
import {InterceptService} from './core/services/intercept.service';
import * as LogRocket from 'logrocket';
import {environment} from '../environments/environment';

if (environment['logrocketAppId']) {
  LogRocket.init(environment['logrocketAppId']);
}

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  wheelSpeed: 0.5,
  swipeEasing: true,
  minScrollbarLength: 40,
  maxScrollbarLength: 300,
};

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserAnimationsModule,
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    AppRoutingModule,
    HttpClientModule,
    CoreModule,
    StoreModule.forRoot(reducers, { metaReducers }),
    EffectsModule.forRoot([]),
    NgbModule,
    TranslateModule.forRoot(),
    InlineSVGModule.forRoot(),
    PartialsModule,
    // AngularFontAwesomeModule
  ],
  exports: [],
  providers: [
    CookieService,
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
    },
    SplashScreenService,
    InterceptService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: InterceptService,
      multi: true,
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
