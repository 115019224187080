import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {from, Observable, throwError} from 'rxjs';
import {catchError, switchMap} from 'rxjs/operators';
import {Router} from '@angular/router';
import {ToastService} from './toast/toast.service';
import {EventTypes} from './toast/models/event-types';


@Injectable()
export class InterceptService implements HttpInterceptor {
  constructor(
    private router: Router,
    private toasts: ToastService
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((failedRequest) => {
        if (failedRequest.status === 401) {
          this.toasts.showToast('Unauthorised', 'Please ask dev team', EventTypes.Error, 10000);
          return throwError('Unauthorised error!');
        } else if (failedRequest.status === 600) {
          this.toasts.showToast('Server failed!', failedRequest.error.error || failedRequest.error.message, EventTypes.Error, 10000);
          return throwError('This is a server error!');
        } else if (failedRequest.status === 422) {
          return this.unprocessableEntityHandler(failedRequest);
        } else if (failedRequest.status === 500) {
          return this.internalServerErrorHandler(request, failedRequest);
        } else if (failedRequest.status === 404) {
          this.toasts.showToast('Server failed!', failedRequest.error, EventTypes.Error, 10000);
          return throwError(failedRequest);
        } else {
          this.toasts.showToast('Server failed!', 'Something went wrong', EventTypes.Error, 10000);
          return throwError(failedRequest);
        }
      }),
    );
  }

  private transformHttpErrorToJsonOrString(httpError: any): Promise<any> {
    return new Promise((resolve, reject) => {
      if (httpError instanceof ArrayBuffer) {
        resolve(String.fromCharCode.apply(null, new Uint8Array(httpError)));
      } else if (httpError instanceof Blob) {
        const blobReader = new FileReader();

        blobReader.addEventListener('loadend', () => {
          try {
            const parsed = JSON.parse(blobReader.result as string);
            resolve(parsed);
          } catch {
            resolve(blobReader.result);
          }
        });

        blobReader.readAsText(httpError);
      } else {
        resolve(httpError);
      }
    });
  }

  private internalServerErrorHandler(request, failedRequest): Observable<any> {
    this.toasts.showToast('Internal server error', 'Internal server error', EventTypes.Error, 10000);
    return throwError(failedRequest);
  }

  private unprocessableEntityHandler(failedRequest): Observable<any> {
    return from(
      this.transformHttpErrorToJsonOrString(failedRequest.error),
    ).pipe(
      switchMap((err) => {
        let errMsg = '';

        err.errors.forEach((element) => {
          errMsg += Array.isArray(element.desc)
            ? `${element.desc.join('. ')} \n`
            : `${element.desc}. <br/>`;
        });
        this.toasts.showToast('Unprocessable', errMsg, EventTypes.Error, 10000);
        return throwError(err);
      }),
    );
  }

}
