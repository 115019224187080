export class ProviderModel {
  id = 0;
  name = '';
  headquarterId = 0;
  active = false;
  image = '';
  askIsEmployedAtAuthorityPosition = false;


  constructor(init?: Partial<ProviderModel>) {
    Object.assign(this, init);
  }
}

