<!--begin: Search -->
<div ngbDropdown (openChange)="openChange()" placement="bottom-right" autoClose="outside" class="header-search-item">

  <button ngbDropdownToggle type="button" class="btn btn-light header-search-btn">
    <i *ngIf="!useSVG" [ngClass]="icon"></i>
    <span *ngIf="useSVG" [inlineSVG]="icon"></span>
  </button>

  <div ngbDropdownMenu class="dropdown-menu">
    <div [ngClass]="{'kt-quick-search--has-result': data?.length}" class="kt-quick-search kt-quick-search--inline" id="kt_quick_search_inline">

      <form method="get" class="kt-quick-search__form">
        <div class="input-group" [ngClass]="{'kt-spinner kt-spinner--input kt-spinner--sm kt-spinner--brand kt-spinner--right': loading}">
          <div class="input-group-prepend"><span class="input-group-text"><i class="flaticon2-search-1"></i></span></div>
          <input #searchInput (keyup)="search($event)" type="text" class="form-control kt-quick-search__input" placeholder="Search...">
          <div class="input-group-append"><span class="input-group-text"><i (click)="clear($event)" [ngStyle]="{'display': 'flex'}" [hidden]="!data || !data?.length" class="la la-close kt-quick-search__close"></i></span></div>
        </div>
      </form>

      <div [perfectScrollbar]="{wheelPropagation: false}" [ngStyle]="{'max-height': '40vh', 'position': 'relative'}" class="kt-quick-search__wrapper">
        <kt-search-result [data]="data"></kt-search-result>
      </div>

    </div>
  </div>

</div>
<!--end: Search -->
