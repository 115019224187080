<section class="title">
    <h5 class="title_text">Tillagt i varukogen!</h5>
    <!-- <button class="btn-close" (click)="onNoClick()">
        <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd"
            d="M13 25C19.6274 25 25 19.6274 25 13C25 6.37258 19.6274 1 13 1C6.37258 1 1 6.37258 1 13C1 19.6274 6.37258 25 13 25Z"
            fill="white" stroke="#CCCCCC" />
        <path d="M17.9424 8.01855L7.94243 18.0186" stroke="black" stroke-width="2" />
        <path d="M17.9424 18.0186L7.94243 8.01855" stroke="black" stroke-width="2" />
        </svg>
    </button> -->
</section>
<section class="main">
    <p class="bold">Gå till kassan för att slutföra din beställning</p>
    <p>Du slutför ditt köp genom att välja "Snabbkassa" i nästa steg.</p>
    <button class="btn btn-light loading-button" (click)="redirect()">
        Gå till kassan
        <mat-spinner class="final-spinner" [diameter]="20" *ngIf="loading"></mat-spinner>
    </button>
</section>