import { Action } from '@ngrx/store';
import { DeviceModel } from '../../models/device.model';
import { ContractModel } from '../../models/contract.model';
import { OrderModel } from '../../models/order.model';
import { AdditionalProduct } from '../../models/additional-parameter.model';
import {SelectedCampaign} from "../../../views/pages/contract-flow/campaigns/models/campaign";


export enum ActionTypes {
  RequestedDevice = '[contract device] get device from server',
  SelectDevices = '[Select Devices Component] Devices get one from server',
  SetOptionsContract = '[set Providers Component]',
  SetSampleOrders = '[set Order Component]',
  SetCustomer = '[set Customer Component] Customer Information',
  SetActionInfo = '[set Customer Component] Customer Action Information',
  SetCheckout = '[set Checkout Component] Customer Action Checkout',
  Reset = '[Reset] Reset Information',
  PageContentLoading = '[page Content Toggle] Orders Page Toggle Loading',
  SetSelectedAdditionalProducts = '[set Additional Products] array selected Additional Products',
  SetPersonalData = '[set personal data] setting personal data',
  SetSelectedAdditionalProductsNumbers = '[set Additional Products with numbers] array selected Additional Products with numbers',
  UpdatedAdditionalProducts = '[set Checkout Component] update Additional products',
  SetCampaigns = '[set campaigns Component]',
}

/**
 * Выбранный девайс
 */
export class ActionSelectDevices implements Action {
  readonly type = ActionTypes.SelectDevices;

  constructor(public payload: DeviceModel) {
  }
}

export class ActionRequestedDevice implements Action {
  readonly type = ActionTypes.RequestedDevice;

  constructor(public payload: any) {
  }
}

/**
 * Сохраним Параметры поиска контракта
 */
export class ActionSetOptionsContract implements Action {
  readonly type = ActionTypes.SetOptionsContract;

  constructor(public payload: ContractModel) {
  }
}

/**
 * Обновление Образца договора
 */
export class ActionOrderUpdateOrder implements Action {
  readonly type = ActionTypes.SetSampleOrders;

  constructor(public payload: OrderModel) {
  }
}

/**
 * Информация о закасчике
 */
export class ActionSetCustomer implements Action {
  readonly type = ActionTypes.SetCustomer;

  constructor(public payload: any) {
  }
}

/**
 * Информация
 */
export class ActionSetActionInfo implements Action {
  readonly type = ActionTypes.SetActionInfo;

  constructor(public payload: any) {
  }
}

/**
 * Сброс формы
 */
export class ActionResetInfo implements Action {
  readonly type = ActionTypes.Reset;

  constructor() {
  }
}

/**
 * Готовый договор от сервера.
 */
export class ActionSetCheckout implements Action {
  readonly type = ActionTypes.SetCheckout;

  constructor(public payload: any) {
  }
}


export class ActionUpdatedAdditionalProducts implements Action {
  readonly type = ActionTypes.UpdatedAdditionalProducts;

  constructor(public payload: any) {
  }
}


/**
 * Loading page
 */
export class ActionPageToggleLoading implements Action {
  readonly type = ActionTypes.PageContentLoading;

  constructor(public payload: { isLoading: boolean }) {
  }
}

/**
 * Additional Products
 */
export class ActionSetAdditionalProducts implements Action {
  readonly type = ActionTypes.SetSelectedAdditionalProducts;

  constructor(public payload: AdditionalProduct[]) { }
}

export class ActionSetPersonalData implements Action {
  readonly type = ActionTypes.SetPersonalData;

  constructor(public payload: any) { }
}

export class ActionSetAdditionalProductsNumbers implements Action {
  readonly type = ActionTypes.SetSelectedAdditionalProductsNumbers;

  constructor(public payload: AdditionalProduct[]) { }
}

export class ActionSetCampaigns implements Action {
  readonly type = ActionTypes.SetCampaigns;

  constructor(public payload: SelectedCampaign[]) {}
}

export type ContractActions = ActionSelectDevices
  | ActionSetOptionsContract
  | ActionSetCheckout
  | ActionSetCustomer
  | ActionSetActionInfo
  | ActionOrderUpdateOrder
  | ActionResetInfo
  | ActionUpdatedAdditionalProducts
  | ActionPageToggleLoading
  | ActionSetAdditionalProducts
  | ActionSetPersonalData
  | ActionSetCampaigns
  | ActionSetAdditionalProductsNumbers;
