import { ActionReducerMap, MetaReducer } from '@ngrx/store';
import { storeFreeze } from 'ngrx-store-freeze';

import { environment } from '../../../environments/environment';
import { ContractReducerState, ContractReducer } from '../contract/_reducers/contract.reducers';

// tslint:disable-next-line:no-empty-interface
export interface AppState {
  ContractStore: ContractReducerState
}

export const reducers: ActionReducerMap<AppState> = {
  ContractStore: ContractReducer
};

export const metaReducers: MetaReducer<AppState>[] = !environment.production ? [storeFreeze] : [];
