import { Injectable } from '@angular/core';

@Injectable()
export class RegexService {

  private nameRegex: string = '^([а-яА-Яa-zA-Z0-9\\s]{3,})*$';

  //// RFC 2822 compliant regex
  // private emailRegex: string = `^(?!.*[\\s])[a-z0-9!#$%&'*+/=?^_\`{|}~-]+(?:\\.[a-z0-9!#$%&'*+/=?^_\`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$`;
  private emailRegex: string = '^[-._a-z0-9]+@(?:[a-z0-9][-a-z0-9]+\\.)+[a-z]{2,6}$'; // simple valid email

  // at least one small letter, one big letter, one numeral, seven characters
  // private passwordRegex: string = '^(?!.*[\s])(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9]).{7,}$'; // минимум 7 значений из которых 1 Большая буква и 1 цифра
  private passwordRegex: string = '[a-zA-Z0-9]{4,20}'; // от 4 до 20 букв и цифр

  private phoneRegex: string = '^[+][\s/\\.,-:0-9]{4,20}$';

  private textRegex: string = '^([а-яА-Яa-zA-Z0-9.:,-/ ()\\n]{3,})*$';


  get name(): string {
    return this.nameRegex;
  }

  get email(): string {
    return this.emailRegex;
  }

  get password(): string {
    return this.passwordRegex;
  }

  get phoneNumber(): string {
    return this.phoneRegex;
  }

  get text(): string {
    return this.textRegex;
  }

}
