import { Subscription } from 'rxjs';
import { ChangeDetectionStrategy, Component, OnDestroy, OnInit, AfterViewInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { SplashScreenService, TranslationService } from './core/_base/layout';
import { environment } from '../environments/environment';

import { locale as enLang } from '../assets/i18n/en';
import { locale as seLang } from '../assets/i18n/se';
import { locale as huLang } from '../assets/i18n/hu';

@Component({
  selector: 'kt-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppComponent implements OnInit, OnDestroy, AfterViewInit {
  title = 'Media Market';
  loader: boolean = true;
  private unsubscribe: Subscription[] = [];
  appLang = 'hu';

  constructor(
    private translationService: TranslationService,
    private router: Router,
    private splashScreenService: SplashScreenService
  ) {
    // register translations
    this.translationService.loadTranslations(enLang, seLang, huLang);
  }

  ngOnInit(): void {
    const routerSubscription = this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        // hide splash screen
        // this.splashScreenService.hide();
        // scroll to top on every route change
        window.scrollTo(0, 0);
      }
    });
    this.unsubscribe.push(routerSubscription);
    this.setAppLang();
  }

  setAppLang(): void {
    const domain = new URL(window.location.origin).host.split('.');
    this.appLang = environment.production ? domain[domain.length - 1] : environment.locale;
    this.translationService.setLanguage(this.appLang);
    console.log( '==========' );
    console.log( 'new URL(window.location.origin) domain: ', new URL(window.location.origin) );
    console.log( 'app.compoent / setAppLang - domain: ', domain );
    console.log( 'app.compoent / setAppLang - this.appLang: ', this.appLang );
    console.log( '==========' );
  }

  ngAfterViewInit() {
    this.loader = false;
  }

  ngOnDestroy() {
    this.unsubscribe.forEach(sb => sb.unsubscribe());
  }
}
