import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {Observable} from "rxjs";
import {OrderCreationResult} from "../_base/crud/models/order";

const API_ORDER_URL = `${environment.api_url}/order`;

@Injectable({providedIn: 'root'})
export class OrderService {

  constructor(
    private http: HttpClient,
  ) {
  }

  getSalesOrder(data: any) {
    return this.http.get(`${API_ORDER_URL}/${data.id}/${data.hash}`);
  }

  getOrderCalculations(data): Observable<any> {
    return this.http.post(`${API_ORDER_URL}/calculate/mediamarkt`, data);
  }

  finishOrder(payload): Observable<Array<OrderCreationResult>> {
    return this.http.post<Array<OrderCreationResult>>(`${API_ORDER_URL}/new/online/mediamarkt`, payload);
  }

  checkSignmentStatus(orderId: number) {
    const body = {
      orderIds: [orderId],
    };
    return this.http.post(`${API_ORDER_URL}/agreements`, body);
  }
}
