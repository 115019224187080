import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-cart-redirection',
  templateUrl: './cart-redirection.component.html',
  styleUrls: ['./cart-redirection.component.scss']
})
export class CartRedirectionComponent implements OnInit {

  public loading = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<CartRedirectionComponent>,
  ) { }

  ngOnInit() {
  }

  redirect() {
    this.loading = true;
    setTimeout(() => {
      (window as any).parentIFrame.sendMessage(
        JSON.stringify({
          action: 'addToCart',
          data: this.data.catentryIds
        })
      );
    }, 0);
  }

  onNoClick() {
    this.dialogRef.close();
  }

}
