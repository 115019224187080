<div class="discount">
  <h5>Use discount code</h5>

  <form class="input-group">
    <input type="text" class="form-control" >
    <div class="input-group-append">
      <button class="btn btn-outline-secondary" type="button">
        <mat-icon aria-hidden="false">keyboard_arrow_right</mat-icon>
      </button>
    </div>
  </form>

  <section class="discount-result">
    <p class="item-line">
      <span class="" >freight transportation</span>
      <small class="" >0 kr</small>
    </p>
    <p class="item-line">
      <span class="" >inclusive of VAT 25%</span>
      <small class="" >2338.00 kr</small>
    </p>
    <p class="item-line">
      <span class="" >Total</span>
      <small class="" style="color: red">116 kr</small>
    </p>
  </section>

  <section class="button-actions" >
    <button type="button" class="btn btn-dark">checkout</button>
    <button type="button" class="btn btn-light">
      continue shopping
      <mat-icon>keyboard_arrow_right</mat-icon>
    </button>
  </section>
</div>

<div class="certificates">
  <div class="item-col">
    <div class="item-line customerPromises">
      <img [src]="'./assets/media/icons/checked.svg'"  alt="">
      <span>free return in the department stores</span>
    </div>
    <div class="item-line customer">
      <img [src]="'./assets/media/logos/customer-reviews.png'"  alt="customer">
    </div>
  </div>
  <div class="item-col">
    <div class="item-line customerPromises">
      <img [src]="'./assets/media/icons/checked.svg'"  alt="">
      <span>open cup for 30 days</span>
    </div>
    <div class="item-line security">
      <div class="d-flex flex-row">
        <div>
          <img [src]="'./assets/media/logos/security-e-handel.png'"  style="width: 100%;" alt="security">
        </div>
        <div>
          <img [src]="'./assets/media/logos/security-kundkontakt.png'" style="width: 100%;" alt="security">
        </div>
      </div>
    </div>
  </div>
</div>
