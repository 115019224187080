import { DeviceModel } from './device.model';
import { ContractModel } from './contract.model';

export class OrderCheckoutModel {
  contract: ContractModel = new ContractModel(); // выбранное
  device: DeviceModel = new DeviceModel(); // выбранное
  subvention: {
    amountExclVAT: number
    articleName: string
    articleNumber: string
    id: number
    monthlyCost: number
    months: number
    providerId: number
  }; // расчеты от бека, для ордера.
  subventions: { monthlyCost: number, amountExcludeVAT: number }[]; // доступные растрочки для слайдера
  extraSubventions: [];  // ?
  discount: number;
  toPay: number;  // device
  total: number;  // device + contract
  additionalServices: Array<any>;
  minimumTotalCost: number;

  constructor(init?: Partial<OrderCheckoutModel>) {
    Object.assign(this, init);
  }
}



