import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'kt-brand',
  templateUrl: './brand.component.html',
})
export class BrandComponent implements OnInit {
  // Public properties
  headerLogo: string;
  headerStickyLogo: string;

  constructor() {
  }

  ngOnInit(): void {
    this.headerLogo = './assets/media/logos/logo-dark.png';
    this.headerStickyLogo = './assets/media/logos/logo-dark.png';
  }
}
