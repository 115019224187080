import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {Observable, of} from 'rxjs';
import { environment } from '../../../environments/environment';
import {ContractModel} from "../models";


@Injectable({ providedIn: 'root' })
export class DeviceService {
  private readonly apiUrl = environment.api_url;
  constructor(
    private http: HttpClient,
  ) {
  }

  getOneDevice(deviceId: string | number): Observable<any> {
    return this.http.get<any>(`${this.apiUrl}/device/article/${deviceId}`);
  }

  autocompleteDevices(query: string): Observable<any[]> {
    const httpHeaders = new HttpHeaders();
    httpHeaders.set('Content-Type', 'application/json');
    return this.http.get<any>(`${this.apiUrl}/device/search?query=${query}`);
  }

  getContractById(deviceArticle: number, contractArticle: number): Observable<ContractModel> {
    return this.http.get<ContractModel>(`${this.apiUrl}/device/${deviceArticle}/contracts/${contractArticle}/mediamarkt`);
  }
}
