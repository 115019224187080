<div class="dialog">
  <button class="btn-close" (click)="onNoClick()">
    <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd"
        d="M13 25C19.6274 25 25 19.6274 25 13C25 6.37258 19.6274 1 13 1C6.37258 1 1 6.37258 1 13C1 19.6274 6.37258 25 13 25Z"
        fill="white" stroke="#CCCCCC" />
      <path d="M17.9424 8.01855L7.94243 18.0186" stroke="black" stroke-width="2" />
      <path d="M17.9424 18.0186L7.94243 8.01855" stroke="black" stroke-width="2" />
    </svg>
  </button>

  <div class="card-device">
    <div class="device-icon">
      <img [attr.src]="device.image" alt="Device">
    </div>
    <div class="device-description"> {{device.articleName}}</div>
    <div class="device-order"> {{device.stockSalesPrice}} kr</div>
  </div>

  <!-- <section>
    <p>color: <span>{{selectColor.name}}</span> </p>
    <ul>
      <li class="item-color" *ngFor="let item of device.colors" [style.background]="item.value"
        [class.active-color]=" item.value === selectColor.value" (click)="selectColor = item"></li>
    </ul>
  </section>

  <section>
    <p>Storage capacity: <span>{{selectStorageCapacity}}</span> GB </p>
    <ul>
      <li class="item-storage-capacity" *ngFor="let item of existStorageCapacity"
        [class.active-storage-capacity]=" item === selectStorageCapacity" (click)="selectStorageCapacity = item">
        {{item}} GB </li>
    </ul>
  </section>

  <section>
    <p>Display size (inch): <span>{{selectDisplaySize}}</span> tum</p>
    <ul>
      <li class="item-display-size" *ngFor="let item of existDisplaySize"
        [class.active-select-display-size]=" item === selectDisplaySize" (click)="selectDisplaySize = item">{{item}} tum
      </li>
    </ul>
  </section> -->

  <div class="dialog-actions">
    <button class="change"
      (click)="openSearchDialog()">{{ 'LANDING_PAGE.MODIFY_PHONE_DIALOG.CHANGE_PHONE' | translate }}</button>
    <button class="done" (click)="onNoClick()">{{ 'LANDING_PAGE.MODIFY_PHONE_DIALOG.DONE' | translate }}</button>
  </div>
</div>
