<nav aria-label="breadcrumb">
  <ol class="breadcrumb">
    <li class="breadcrumb-item">
      <a href="/varukor">Varukor</a>
    </li>
    <li class="breadcrumb-item">
      <a href="/kassa">Kassa</a>
    </li>
    <li class="breadcrumb-item active" aria-current="page">
      Data
    </li>
  </ol>
</nav>
