import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { SearchPhoneComponent } from '../search-phone/search-phone.component';
import { AppState } from 'src/app/core/reducers';
import { Store } from '@ngrx/store';
import { takeUntil, map } from 'rxjs/operators';
import { ContractReducerState } from 'src/app/core/contract';
import { DeviceModel } from 'src/app/core/models';

@Component({
  selector: 'kt-modify-phone',
  templateUrl: './modify-phone.component.html',
  styleUrls: ['./modify-phone.component.scss']
})
export class ModifyPhoneComponent implements OnInit {

  loaderDeviceIcon: string;
  device: DeviceModel;

  selectColor;

  existStorageCapacity = ['64', '128', '256'];
  selectStorageCapacity = this.existStorageCapacity[0];

  existDisplaySize = ['5.8', '6.5'];
  selectDisplaySize = this.existDisplaySize[0];

  constructor(
    public dialogRef: MatDialogRef<ModifyPhoneComponent>,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private store: Store<AppState>,
  ) {
  }

  ngOnInit() {
    this.loaderDeviceIcon = './assets/media/products/product1.png';
    this.store
      .select('ContractStore')
      .pipe(
        map((state: ContractReducerState) => state.selectDevices)
      )
      .subscribe((device: DeviceModel) => {
        this.device = device;
        this.selectColor = device.colors[0];
      });
  }

  onNoClick(): void {
    this.dialogRef.close();
  }


  openSearchDialog(): void {

    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.minWidth = 320;
    dialogConfig.width = '600px';
    if (screen.width > 750) {
      dialogConfig.height = '60vh';
    } else {
      dialogConfig.height = `${screen.height - 185}px`;
    }
    dialogConfig.id = 'search';
    dialogConfig.maxWidth = 800;

    dialogConfig.data = { name: 'name data' };

    const dialogRef = this.dialog.open(SearchPhoneComponent, dialogConfig);

    let el = (<any>document).getElementsByTagName('mat-dialog-container')[1];

    if ((<any>window).parentIFrame) {
      setTimeout(() => {
        (<any>window).parentIFrame.scrollTo(0, 0);
      }, 0);
    } else {
      el.scrollIntoView();
    }


    dialogRef.afterClosed().subscribe(result => {
      if (result === 'redirect') {
        this.dialogRef.close();
      }
    });
  }
}
