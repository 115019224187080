import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';
import {ContractModel} from '../models';


@Injectable({providedIn: 'root'})
export class ContractService {

  private readonly API_URL = environment.api_url;
  constructor(
    private http: HttpClient,
  ) {
  }

  getAllProviderContracts(searchParams: any, deviceId: number): Observable<Array<ContractModel>> {
    let params = new HttpParams()
      .set('dataIncludedMin', searchParams.dataIncluded)
      .set('productGroup', searchParams.productGroup)
      .set('extraSimExists', searchParams.extraSimExists);


    if (searchParams.contractLength) {
      params = new HttpParams()
        .set('dataIncludedMin', searchParams.dataIncluded)
        .set('productGroup', searchParams.productGroup)
        .set('contractLength', String(searchParams.contractLength))
        .set('extraSimExists', searchParams.extraSimExists);
    }
    if (searchParams.extraSimExists !== undefined) {
      params.append('extraSimExists', searchParams.extraSimExists);
    }

    return this.http.get<ContractModel[]>(this.API_URL + `/device/${deviceId}/contracts/mediamarkt`, { params });
  }

}
