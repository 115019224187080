<!-- BEGIN: Horizontal Menu -->
<button type="button" class="btn btn-light btn-toggle-menu">
  <mat-icon aria-hidden="false" aria-label="Example home icon">menu</mat-icon>
  <p>menu</p>
</button>

<i class="fas fa-user-secret"></i>

<div  class="kt-header-menu-wrapper" >
  <div id="kt_header_menu" class="kt-header-menu kt-header-menu-mobile" >
    <ul class="kt-menu__nav" >
      <ng-container *ngFor="let item of menuHorService ">
        <ng-container *ngIf="item.title" [ngTemplateOutlet]="menuTemplate" [ngTemplateOutletContext]="{ item: item }"></ng-container>
      </ng-container>
    </ul>
  </div>
</div>
<!-- END: Horizontal Menu -->






<ng-template #menuTemplate let-item="item" let-parentItem="parentItem">
  <li (mouseleave)="mouseLeave($event)" (mouseenter)="mouseEnter($event)" >

    <!-- if item has submenu -->
    <ng-container *ngIf="item.submenu">
      <a href="javascript:;" class="kt-menu__link">

        <ng-container [ngTemplateOutlet]="menuItemInnerTemplate" [ngTemplateOutletContext]="{ item: item, parentItem: parentItem }"></ng-container>

        <ng-container *ngIf="false">
          <!-- arrow icons -->
          <i *ngIf="item.submenu && item.root" class="kt-menu__hor-arrow la la-angle-down"></i>
          <i *ngIf="item.submenu && item.root" class="kt-menu__ver-arrow la la-angle-right"></i>
        </ng-container>
        <!-- else arrow icons -->
        <i *ngIf="item.submenu && !item.root" class="kt-menu__hor-arrow la la-angle-right"></i>
        <i *ngIf="item.submenu && !item.root" class="kt-menu__ver-arrow la la-angle-right"></i>
      </a>
    </ng-container>

    <!-- if item hasn't sumbenu -->
    <ng-container *ngIf="!item.submenu">
      <a [routerLink]="item.page" [ngClass]="{ 'kt-menu__toggle': item.root }" class="kt-menu__link">
        <ng-container [ngTemplateOutlet]="menuItemInnerTemplate" [ngTemplateOutletContext]="{ item: item, parentItem: parentItem }"></ng-container>
      </a>
    </ng-container>

    <!-- if menu item has submenu child then recursively call new menu item component -->
    <ng-container *ngIf="item.submenu">
      <div class="kt-menu__submenu" [ngStyle]="{ 'width': item.submenu.width }">
        <span class="kt-menu__arrow" [ngClass]="{ 'kt-menu__arrow--adjust': item.root }"></span>

        <ul *ngIf="item.submenu?.length" class="kt-menu__subnav">
          <ng-container *ngFor="let child of item.submenu">
            <ng-container [ngTemplateOutlet]="menuTemplate" [ngTemplateOutletContext]="{ item: child, parentItem: item }">
            </ng-container>
          </ng-container>
        </ul>

        <ul *ngIf="item.submenu.items?.length" class="kt-menu__subnav">
          <ng-container *ngFor="let child of item.submenu.items">
            <ng-container [ngTemplateOutlet]="menuTemplate" [ngTemplateOutletContext]="{ item: child, parentItem: item }">
            </ng-container>
          </ng-container>
        </ul>

        <div *ngIf="item.submenu.type === 'mega' && item.submenu.columns?.length" class="kt-menu__subnav">
          <ul class="kt-menu__content">
            <ng-container *ngFor="let child of item.submenu.columns">
              <ng-container [ngTemplateOutlet]="menuColumnTemplate" [ngTemplateOutletContext]="{ item: child }"></ng-container>
            </ng-container>
          </ul>
        </div>

      </div>
    </ng-container>

  </li>
</ng-template>



<!-- item inner -->
<ng-template #menuItemInnerTemplate let-item="item" let-parentItem="parentItem">
  <!-- if menu item has icon -->
  <i *ngIf="item.icon" class="kt-menu__link-icon" [ngClass]="item.icon"></i>

  <ng-container *ngIf="!item.icon">
    <!-- if menu item using bullet -->
    <i *ngIf="parentItem && parentItem.bullet === 'dot' || item.bullet === 'dot'" class="kt-menu__link-bullet kt-menu__link-bullet--dot">
      <span></span>
    </i>
    <i *ngIf="parentItem && parentItem.bullet === 'line' || item.bullet === 'line'" class="kt-menu__link-bullet kt-menu__link-bullet--line">
      <span></span>
    </i>
  </ng-container>

  <ng-container *ngIf="!item.badge; else m_menu_link_badge">
    <span class="kt-menu__item-here"></span>
    <!-- menu item title text -->
    <span class="kt-menu__link-text" [translate]="item.translate">
			{{item.title}}
		</span>
  </ng-container>

  <ng-template #m_menu_link_badge>
    <!-- menu item with badge -->
    <span class="kt-menu__link-text" [translate]="item.translate">{{item.title}}</span>
    <span class="kt-menu__link-badge">
			<span class="kt-badge kt-badge--brand kt-badge--inline kt-badge--pill" [ngClass]="item.badge.type" [translate]="item.badge.translate">{{item.badge.value}}</span>
		</span>
  </ng-template>
</ng-template>



<!-- item column -->
<ng-template #menuColumnTemplate let-item="item">
  <li class="kt-menu__item">
    <h3 class="kt-menu__heading kt-menu__toggle">
			<span class="kt-menu__link-text" [translate]="item.heading.translate">
				{{item.heading.title}}
			</span>
      <i class="kt-menu__ver-arrow la la-angle-right"></i>
    </h3>
    <ng-container *ngIf="item.items?.length">
      <ul class="kt-menu__inner">
        <ng-container *ngFor="let child of item.items">
          <ng-container [ngTemplateOutlet]="menuTemplate" [ngTemplateOutletContext]="{ item: child, parentItem: item }">
          </ng-container>
        </ng-container>
      </ul>
    </ng-container>
  </li>
</ng-template>
