import {ContractTypeModel} from './contract-type.model';
import {ContractLengthModel} from './contract-length.model';
import {ProviderModel} from './provider.model';

export enum TypeCalculationUsageData {
  calculator = 'Calculator',
  simple = 'Simple'
}

export class ContractModel {
  id?: number;
  providerId = 0;
  provider: ProviderModel = new ProviderModel();
  articleNumber = 0;
  articleName = '';
  totalCommission = 0;
  marginPercent = 0;
  amountKept = 0;
  active = false;
  contractType = '';
  productGroup: ContractTypeModel = ContractTypeModel.new ;
  contractLength = '';
  extraSimExists = false;
  calculationType: TypeCalculationUsageData = TypeCalculationUsageData.calculator;
  calculationModel = 0;
  monthlyCost = 0;
  monthlyDiscount = 0;
  dataIncluded = 0;
  startingFee = 0;
  startingFeeInstore = false;
  subvention: any = null;
  totalMonthlyCost = 0;
  product: any = null;

  constructor(init?: Partial<ContractModel>) {
    Object.assign(this, init);
  }
}
