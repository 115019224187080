export const locale = {
  lang: 'hu',
  data: {
    GO_BACK: 'Menjen vissza',
    LANDING_PAGE: {
      CURRENCY: 'Ft',
      MODIFY: 'Módosít',
      OPEN_BANK_ID: 'Öppna din BankID app',
      SUMMARY: 'Összefoglaló',
      PER_MONTH: 'Havonta',
      CHOOSE_CONTRACT: 'Välj abonnemang',
      CONTINUE: 'Gå vidare',
      CHOOSE_ADDITIONAL_PRODUCTS: 'Välj tillägg',
      CHOOSE_NUMBER: 'Välj nummer',
      PERSONAL_DETAILS: 'Personuppgifter',
      CONFIRMATION: 'Jóváhagyásba',
      REGISTRATION_OF_CONTRACT: 'Tecknande av abonnemang',
      ADD_TO_BASKET_INSTRUCTIONS: '(Kryssruta) Jag godkänner operatörens allmänna villkor och att en kreditupplysning genomförs av operatören.',
      ADD_TO_BASKET_INSTRUCTIONS2: 'När kreditupplysningen godkänts kommer operatören att skicka ett abonnemangsavtal till den e-post du angivit och be dig signera det.',
      ADD_TO_BASKET_INSTRUCTIONS3: 'I nästa steg slutför du köpet av mobilen.',
      CREDIT_CHECK_APPROVEMENT: 'Jag godkänner operatörens villkor och avtalet samt är införstådd med att detta köp medför ett betalningsansvar och att en kreditkontroll kommer genomföras. Vid tecknande av abonnemang hos oss kommer vi att kontakta dig för att verifiera och signera beställningen.',
      ADD_TO_BASKET: 'Lägg i varukorgen',
      MODIFY_PHONE_DIALOG: {
        CHANGE_PHONE: 'Byt telefon',
        DONE: 'Klar'
      },
      SEARCH_PHONE_DIALOG: {
        SEARCH_PHONE: 'Sök mobiltelefon'
      },
      CHOOSE_CONTRACT_BLOCK: {
        MORE_CONTRACTS: 'Visa mer',
        GET_NEW_CONTRACT_OR_EXTEND_EXISTING: 'Vill du byta operatör, teckna helt nytt nummer eller behålla din nuvarande operatör?',
        NEW_CONTRACT: 'Teckna nytt/Byt operatör',
        CONTRACT_EXTENSION: 'Behåll nuvarande operatör',
        SHARE_SUFR_WITH_MULTIPLE_SIM_CARDS: 'Dela på surfen/koppla på en extra användare med ett till SIM-kort',
        CONNECT_ADDITIONAL_SIM_CARDS: 'Lägg till ytterligare SIM kort',
        HOW_MUCH_SURF_DO_YOU_NEED: 'Hur mycket surfar du',
        EXTRA_SIM_CARD_DESCRIPTION: 'Ett extra SIM-kort fungerar som en klon av abonnemanget med samma funktionalitet, vilket är användbart när du vill dela på ett abonnemang på minst två personer.',
        EXTRA_SIM_CARD_DESCRIPTION2: 'För närvarande är det endast operatörer listade nedan som erbjuder extra SIM-kort kopplat till ett och samma abonnemang.',
        SELECT_CONTRACT_PERIOD: 'Välj bindningstid',
        MONTH: 'mån',
        MATCHED_CONTRACTS: 'Se matchande abonnemang nedan',
        FILTER: 'Filter',
        SORT: 'Sortera',
        SORT_VALUES: {
          OPERATOR: 'Operatör',
          PRICE: 'Pris',
          SURF: 'Surf (GB)',
        },
        INCL_MOBILE_PHONE: 'inkl. mobiltelefon',
        MORE_INFO: 'Mer info',
        SURF_CALCULATOR: {
          HEADER: 'Hur mycket surfar du?',
          TITLE: 'Surfkalkylator',
          FIRST_TAB: 'Per månad',
          SECOND_TAB: 'Per dag',
          FIRST_TAB_SECTION: {
            START_POINT: 'Lite',
            MIDDLE_POINT: 'Medel',
            END_POINT: 'Mycket'
          },
          SECOND_TAB_SECTION: {
            HEADER: 'Hur mycket surfar du per dag?',
            SLIDER_NAMES: ['Sociala Medier', 'Video', 'Musik', 'Övrig surf'],
            UNITS: 'Timmar'
          },
          TOTAL_DATA: 'Total Data',
          SURF_MONTH: 'GB surf/mån'
        }
      },
      CHOOSE_ADDITIONAL_PRODUCTS_BLOCK: {
        ADDITIONAL_PRODUCTS_ARE_AVAILABLE_FOR_SELECTED_CONTRACT: 'Grattis! Just nu får du som köper mobil med abonnemang välja en bonuspryl på köpet - helt utan extra kostnad. Kampanjen gäller tom 2/8-2020.',
        NO_AVAILABLE_FOR_SELECTED_CONTRACT: 'Det finns inga valbara tillägg till detta abonnemang',
        PERSONS: 'Personer',
        SELECT: 'Välj',
        UNSELECT: 'Ändra',
        SELECTED: 'Vald'
      },
      CHOOSE_NUMBER_BLOCK: {
        YOUR_NUMBER: 'Välj ditt nummer',
        GET_NEW_NUMBER: 'Nytt nummer',
        ASSIGNED_NUMBER_BY_PROVIDER: 'Du kommer att tilldelas ett nytt nummer av den operatör du valt',
        KEEP_EXISTING_NUMBER: 'Behåll nummer',
        PORTING_NUMBER_NOTICE: 'Vid byte av operatör flyttas ditt nuvarande telefonnummer till den operatör du valt. Ditt abonnemang hos din nuvarande operatör avslutas automatiskt. Det kan ta upp till en vecka innan nummerflytten sker. Har du bindningstid och/eller uppsägningstid kvar hos din nuvarande operatör kan du få en slutfaktura.',
        MY_EXISTING_NUMBER: 'Mitt nuvarande telefonnummer',
      },
      PERSONAL_DETAILS_BLOCK: {
        FILL_SOCIAL_SECURITY_NUMBER: 'För att teckna abonnemang behöver du fylla i ditt personnummer.',
        SOCIAL_SECURITY_NUMBER: 'Personnummer',
        SOCIAL_SECURITY_NUMBER_ERROR: 'Ogiltigt personnummer',
        EMAIL: 'E-post',
        EMAIL_ERROR: 'Ogiltig E-postadress',
        REPEAT_EMAIL: 'Bekräfta e-post',
        NOT_YOU_CHANGE: 'Inte du?/ ändra',
        PURCHASE_NOTE: 'Vid abonnemangsköp skickas leverans till din folkbokföringsadress från oss så fort registrering av abonnemanget skett. Paketet levereras till ditt närmsta ombud.',
        CONFIRM_EMAIL_ERROR: 'E-postadressen stämmer ej',
        NUMBER: 'Phone number',
        NUMBER_ERROR: 'Phone number doesn\'t match',
      },
      SUMMARY_BLOCK: {
        PHONE: 'Hårdvara',
        PAY_NOW: 'Startavgift',
        MONTHS: 'Månader',
        MONTH: 'mån',
        CONTRACT: 'Abonnemang',
        ADD_ON: 'Tillägg',
        TO_PAY: 'Att betala',
        ONE_TIME_FEE: 'Engångskostnad',
        TOTAL_MONTHLY_COST: 'Total månadskostnad',
        MINIMUM_TOTAL_COST: 'Minsta totalkostnad {{contractLength}} månader',
        SAVING_AMOUNT_COST: 'Rabatt på telefon',
      },
      CAMPAIGNS_BLOCK: {
        CAMPAIGNS: 'Campaigns',
        CHOOSE: 'Choose',
        OF_THE_FOLLOWING: 'of the following products',
        VALUE: 'Value'
      },
    },
    CAMPAIGNS_BLOCK: {
      CAMPAIGNS: 'Campaigns',
      CHOOSE: 'Choose',
      OF_THE_FOLLOWING: 'of the following products',
      VALUE: 'Value'
    },
  }
};
