import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {SplashScreenService} from '../../../../core/_base/layout';

@Component({
  selector: 'kt-splash-screen',
  templateUrl: './splash-screen.component.html',
  styleUrls: ['./splash-screen.component.scss']
})
export class SplashScreenComponent implements OnInit {
  loaderLogo: string;
  loaderType: string;
  loaderMessage: string;

  // @ts-ignore
  @ViewChild('splashScreen') splashScreen: ElementRef;

  constructor(
    private el: ElementRef,
    private splashScreenService: SplashScreenService) {
  }

  ngOnInit() {
    this.loaderLogo = './assets/media/logos/logo-mini-md.png';
    this.loaderType = 'spinner-logo';
    this.loaderMessage = 'Please wait...';

    this.splashScreenService.init(this.splashScreen);
  }
}
