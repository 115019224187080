export class DeviceModel {
  id: number;
  configuration: null;
  stockSalesPrice: number = 0;
  purchasePrice: number = 0;
  articleName: string = ''; // Название девайса
  articleNumber: string = '';
  description: string = ''; // Описание девайса
  image: string = ''; // Картинка
  discount: number = 0; // Скидка
  colors: { name: string, value: string }[];
  manufacturerId: number = 0;
  manufacturer: { id: number, name: string; }; // производитель
  store: any;
  headquarterId: any;
  headquarter: any;

  constructor(init?: Partial<DeviceModel>) {
    Object.assign(this, init);
  }
}
