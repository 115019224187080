import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ToastEvent} from "../../../../core/services/toast/models/toast.event";
import {EventTypes} from "../../../../core/services/toast/models/event-types";

@Component({
    selector: 'kt-toast',
    templateUrl: './toast.component.html',
    styleUrls: ['./toast.component.scss']
})
export class ToastComponent implements OnInit {
    @Input() toast: ToastEvent;
    @Input() i: number;

    @Output() remove = new EventEmitter<number>();

    readonly EventTypes = EventTypes;

    ngOnInit() {
        setTimeout(() => this.remove.emit(this.i),  this.toast.delay || 5000);
    }
}
