import {Injectable} from '@angular/core';
import {AbstractControl, ValidatorFn, Validators} from '@angular/forms';
import {RegexService} from './regex.service';

@Injectable()
export class FormValidationRegExService extends Validators {

	constructor(private regex: RegexService) {
		super();
	}

	name(): ValidatorFn {
		return (control) => {
			const composedValidator = Validators.compose([
				Validators.required,
				Validators.pattern(this.regex.name),
			]);

			return composedValidator(control);
		};
	}

	email(): ValidatorFn {
		return (control) => {
			const composedValidator = Validators.compose([
				Validators.required,
				Validators.pattern(this.regex.email),
			]);

			return composedValidator(control);
		};
	}

	password(): ValidatorFn {
		return (control) => {
			const composedValidator = Validators.compose([
				Validators.required,
				Validators.pattern(this.regex.password),
			]);

			return composedValidator(control);
		};
	}

	// birthDate(): ValidatorFn {
	// 	return (control) => {
	// 		const composedValidator = Validators.compose([
	// 			Validators.required,
	// 			Validators.pattern(this.regex.date),
	// 		]);
	//
	// 		return composedValidator(control);
	// 	};
	// }


	phone(): ValidatorFn {
		return (control) => {
			const composedValidator = Validators.compose([
				Validators.required,
				Validators.pattern(this.regex.phoneNumber),
			]);

			return composedValidator(control);
		};
	}

	text(): ValidatorFn {
		return (control) => {
			const composedValidator = Validators.compose([
				Validators.required,
				Validators.pattern(this.regex.text),
			]);

			return composedValidator(control);
		};
	}

	isObject(control: AbstractControl): { [key: string]: boolean } | null {
		if (typeof control.value !== 'object') {
			return { 'isNotObject': true};
		}
		return null;
	}
}
