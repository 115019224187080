import {ChangeDetectionStrategy, Component, ElementRef, OnInit, Renderer2} from '@angular/core';
import {Router} from '@angular/router';

@Component({
  selector: 'kt-menu-horizontal',
  templateUrl: './menu-horizontal.component.html',
  styleUrls: ['./menu-horizontal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MenuHorizontalComponent implements OnInit {

  menuHorService = [];

  constructor(
    private el: ElementRef,
    private router: Router,
    private render: Renderer2
  ) {
  }

  ngOnInit(): void {
  }

  /**
   * Use for fixed left aside menu, to show menu on mouseenter event.
   * @param e Event
   */
  mouseEnter(e: Event) {
    // check if the left aside menu is fixed
    if (!document.body.classList.contains('kt-menu__item--hover')) {
      this.render.addClass(document.body, 'kt-menu__item--hover');
    }
  }

  /**
   * Mouse Leave event
   * @param event: MouseEvent
   */
  mouseLeave(event: MouseEvent) {
    this.render.removeClass(event.target, 'kt-menu__item--hover');
  }
}
