import { ActionTypes, ContractActions } from '../_actions/contract.actions';
import { ContractModel } from '../../models';
import { DeviceModel } from '../../models/device.model';
import { OrderModel } from '../../models/order.model';
import { OrderCheckoutModel } from '../../models/order-checkout.model';
import { AdditionalProduct } from '../../models/additional-parameter.model';
import {SelectedCampaign} from '../../../views/pages/contract-flow/campaigns/models/campaign';

export interface ContractReducerState {
  selectedAdditionalProductsNumbers: AdditionalProduct[];
  selectedAdditionalProducts: AdditionalProduct[];
  selectDevices: DeviceModel;
  optionsContract: ContractModel;
  sampleOrders: OrderModel;
  customer: any;
  actionInfo: any;
  selectedCampaigns: SelectedCampaign[];
  generatedOrder: OrderCheckoutModel;
}

export const initialContractState: ContractReducerState = {
  selectedAdditionalProductsNumbers: null,
  selectedAdditionalProducts: null,
  selectDevices: new DeviceModel(),
  optionsContract: new ContractModel(),
  sampleOrders: null,
  customer: null,
  actionInfo: null,
  selectedCampaigns: [],
  generatedOrder: null,
};


export function ContractReducer(state = initialContractState, action: ContractActions): ContractReducerState {
  switch (action.type) {

    case ActionTypes.SelectDevices:
      return {
        ...state,
        selectDevices: action.payload
      };
    case ActionTypes.SetOptionsContract:
      return {
        ...state,
        optionsContract: action.payload
      };
    case ActionTypes.SetSampleOrders:
      return {
        ...state,
        sampleOrders: action.payload
      };
    case ActionTypes.SetCustomer:
      return {
        ...state,
        customer: action.payload
      };
    case ActionTypes.SetActionInfo:
      return {
        ...state,
        actionInfo: action.payload
      };
    case ActionTypes.SetCheckout:
      return {
        ...state,
        generatedOrder: action.payload
      };
    case ActionTypes.UpdatedAdditionalProducts:
      return {
        ...state,
        generatedOrder: {
          ...state.generatedOrder,
          total: action.payload.total,
          toPay: action.payload.toPay,
          device: action.payload.device,
          minimumTotalCost: action.payload.minimumTotalCost,
          subvention: action.payload.subvention
        }
      };
    case ActionTypes.SetSelectedAdditionalProducts:
      return {
        ...state,
        selectedAdditionalProducts: [...action.payload]
      }
    case ActionTypes.SetSelectedAdditionalProductsNumbers:
      return {
        ...state,
        selectedAdditionalProductsNumbers: [...action.payload]
      }
    case ActionTypes.SetPersonalData:
      return {
        ...state,
        customer: Object.assign({}, state.customer, action.payload)
      }
    case ActionTypes.Reset:
      return {
        selectedAdditionalProductsNumbers: null,
        selectedAdditionalProducts: null,
        selectDevices: null,
        optionsContract: new ContractModel(),
        sampleOrders: null,
        customer: null,
        actionInfo: null,
        selectedCampaigns: [],
        generatedOrder: null,
      };
    case ActionTypes.SetCampaigns:
      return {
        ...state,
        selectedCampaigns: action.payload
      };

    default:
      return state;
  }
}
