export const locale = {
  lang: 'en',
  data: {
    GO_BACK: 'Go Back',
    LANDING_PAGE: {
      CURRENCY: 'kr',
      MODIFY: 'Modify',
      OPEN_BANK_ID: 'Open BankID application',
      WAITING_FOR_COMPLETE: 'Waiting for signing to complete',
      SUMMARY: 'summary',
      PER_MONTH: 'Per month',
      CHOOSE_CONTRACT: 'Choose contract',
      CONTINUE: 'Continue',
      CHOOSE_ADDITIONAL_PRODUCTS: 'Choose additional products',
      CHOOSE_NUMBER: 'Choose number',
      PERSONAL_DETAILS: 'Personal details',
      CONFIRMATION: 'Confirmation',
      REGISTRATION_OF_CONTRACT: 'Registration of contract',
      ADD_TO_BASKET_INSTRUCTIONS: '(Kryssruta) Jag godkänner operatörens allmänna villkor och att en kreditupplysning genomförs av operatören.',
      ADD_TO_BASKET_INSTRUCTIONS2: 'När kreditupplysningen godkänts kommer operatören att skicka ett abonnemangsavtal till den e-post du angivit och be dig signera det.',
      ADD_TO_BASKET_INSTRUCTIONS3: 'I nästa steg slutför du köpet av mobilen.',
      CREDIT_CHECK_APPROVEMENT: 'I approve that a credit check is done by third party and that the order will be cancelled if this check dosn\'t go though',
      ADD_TO_BASKET: 'Add to basket',
      MODIFY_PHONE_DIALOG: {
        CHANGE_PHONE: 'Change phone',
        DONE: 'Done'
      },
      SEARCH_PHONE_DIALOG: {
        SEARCH_PHONE: 'Search phone'
      },
      CAMPAIGNS_BLOCK: {
        CAMPAIGNS: 'Campaigns',
        CHOOSE: 'Choose',
        OF_THE_FOLLOWING: 'of the following products',
        VALUE: 'Value'
      },
      CHOOSE_CONTRACT_BLOCK: {
        MORE_CONTRACTS: 'Show more',
        GET_NEW_CONTRACT_OR_EXTEND_EXISTING: 'Get a new contract or extend your existing?',
        NEW_CONTRACT: 'New contract',
        CONTRACT_EXTENSION: 'Contract extention',
        SHARE_SUFR_WITH_MULTIPLE_SIM_CARDS: 'Share surf with multiple SIM cards.',
        CONNECT_ADDITIONAL_SIM_CARDS: 'Connect additional SIM cards',
        HOW_MUCH_SURF_DO_YOU_NEED: 'How much surf do you need?',
        EXTRA_SIM_CARD_DESCRIPTION: 'An extra SIM card acts like a clon of your contract with same functionality and services as the main contract. You can share the data between SIM cards.',
        EXTRA_SIM_CARD_DESCRIPTION2: 'Currently extra sim card connected to your extsisting contract is offered by following providers and contract in the list below.',
        SELECT_CONTRACT_PERIOD: 'Select contract period',
        MONTH: 'month',
        MATCHED_CONTRACTS: 'Matched contracts',
        FILTER: 'filter',
        SORT: 'sort',
        SORT_VALUES: {
          OPERATOR: 'Operator',
          PRICE: 'Price',
          SURF: 'Surf (GB)',
        },
        INCL_MOBILE_PHONE: 'inkl mobiltelefon',
        MORE_INFO: 'more info',
        SURF_CALCULATOR: {
          HEADER: 'How much surf do you need?',
          TITLE: 'Surf Calculator',
          FIRST_TAB: 'Simple',
          SECOND_TAB: 'Per Day',
          FIRST_TAB_SECTION: {
            START_POINT: 'Small need of stuff',
            MIDDLE_POINT: 'Moderate stuff',
            END_POINT: 'Large amount of data'
          },
          SECOND_TAB_SECTION: {
            HEADER: 'How much do you surf per day?',
            SLIDER_NAMES: ['Social media', 'Video (YouTube, films, serials)', 'Spotify & radio', 'Web surf'],
            UNITS: 'hours'
          },
          TOTAL_DATA: 'Total Data',
          SURF_MONTH: 'GB surf / month'
        }
      },
      CHOOSE_ADDITIONAL_PRODUCTS_BLOCK: {
        ADDITIONAL_PRODUCTS_ARE_AVAILABLE_FOR_SELECTED_CONTRACT: 'Additional products are available for selected contract',
        NO_AVAILABLE_FOR_SELECTED_CONTRACT: 'There are no selectable add-ons for this contract',
        PERSONS: 'persons',
        SELECT: 'Select',
        UNSELECT: 'Unselect',
        SELECTED: 'Selected'
      },
      CHOOSE_NUMBER_BLOCK: {
        YOUR_NUMBER: 'Your number',
        GET_NEW_NUMBER: 'Get a new number',
        ASSIGNED_NUMBER_BY_PROVIDER: 'Assigned number by provider.',
        KEEP_EXISTING_NUMBER: 'Keep existing number',
        PORTING_NUMBER_NOTICE: 'If you are porting your phone number from another operator it can take up to 5 workdays. You have t own the number. If the provider dosen\'t release the number a new phonnember will be assigned',
        MY_EXISTING_NUMBER: 'My existing number'
      },
      PERSONAL_DETAILS_BLOCK: {
        FILL_SOCIAL_SECURITY_NUMBER: 'Fill in your social security number',
        SOCIAL_SECURITY_NUMBER: 'Social security number',
        SOCIAL_SECURITY_NUMBER_ERROR: 'Invalid social security number',
        EMAIL: 'E-mail',
        EMAIL_ERROR: 'Invalid e-mail',
        REPEAT_EMAIL: 'Repeat your e-mail',
        NOT_YOU_CHANGE: 'Not you? Change',
        PURCHASE_NOTE: 'Purchases with contracts requires delivery to you official registerd address.',
        CONFIRM_EMAIL_ERROR: 'E-mail address doesn\'t match',
        NUMBER: 'Phone number',
        NUMBER_ERROR: 'Phone number doesn\'t match',
      },
      SUMMARY_BLOCK: {
        PHONE: 'Phone',
        PAY_NOW: 'pay now',
        MONTHS: 'Months',
        MONTH: 'month',
        CONTRACT: 'Contract',
        ADD_ON: 'Add on',
        TO_PAY: 'To pay',
        ONE_TIME_FEE: 'One time fee',
        INSTALMENT_FEE: 'Instalment fee',
        TOTAL_MONTHLY_COST: 'Total monthly cost',
        MONTHLY_DISCOUNT: 'Monthly discount',
        MINIMUM_TOTAL_COST: 'Minimum total cost during {{contractLength}} months',
        SAVING_AMOUNT_COST: 'Rabatt på telefon',
      }
    },
    CAMPAIGNS_BLOCK: {
      CAMPAIGNS: 'Campaigns',
      CHOOSE: 'Choose',
      OF_THE_FOLLOWING: 'of the following products',
      VALUE: 'Value'
    },
  }
};
