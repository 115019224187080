<div class="dialog">
  <button class="btn-close" (click)="onNoClick()">
    <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd"
        d="M13 25C19.6274 25 25 19.6274 25 13C25 6.37258 19.6274 1 13 1C6.37258 1 1 6.37258 1 13C1 19.6274 6.37258 25 13 25Z"
        fill="white" stroke="#CCCCCC" />
      <path d="M17.9424 8.01855L7.94243 18.0186" stroke="black" stroke-width="2" />
      <path d="M17.9424 18.0186L7.94243 8.01855" stroke="black" stroke-width="2" />
    </svg>
  </button>


  <section class="search-input">
    <form [formGroup]="searchForm" class="input-group mb-3">
      <input type="text" class="form-control" formControlName='name'
        placeholder="{{ 'LANDING_PAGE.SEARCH_PHONE_DIALOG.SEARCH_PHONE' | translate }}"
        [attr.aria-label]=" 'LANDING_PAGE.SEARCH_PHONE_DIALOG.SEARCH_PHONE' | translate "
        aria-describedby="basic-addon2">
      <div class="input-group-append">
        <button class="btn-clear">
          <mat-icon aria-hidden="false" aria-label="Close">close</mat-icon>
        </button>
        <button class="btn btn-outline-secondary" type="button">
          <mat-icon aria-hidden="false" aria-label="Search">search</mat-icon>
        </button>
      </div>
    </form>
  </section>

  <!-- <section class="sys-filter">
    <button *ngFor="let item of OS" [class.active-btn]="selectOS === item" (click)="selectOS = item">{{item}}</button>
  </section> -->

  <section class="list-search">

    <div class="search-loading" *ngIf="loading">
      <div class="text">Searching...</div>
      <div class="anim">
        <mat-spinner diameter="50"></mat-spinner>
      </div>
    </div>

    <div class="scroll" *ngIf="!loading">
      <a class="item" (click)="selectDevice(item)" *ngFor="let item of resultSearch; let i = index">
        <div class="img">
          <img [src]="item.image" alt="phone">
        </div>
        <div class="description">
          <!-- <div class="logo">{{item.logo}}</div> -->
          <div class="body">
            <span class="title">{{item.articleName}}</span>
          </div>
          <div class="costs">{{item.stockSalesPrice}} kr</div>
        </div>
      </a>
    </div>

  </section>
</div>
