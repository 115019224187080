<div class="card-device">
  <section>
    <div class="device-icon">
      <img [attr.src]="loaderDeviceIcon" alt="Device">
    </div>
    <div class="device-description">
      <h4>Lorem ipsum dolor sit amet.</h4>
      <h5>2800 kr</h5>
      <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Animi asperiores assumenda cumque cupiditate eos esse
        explicabo porro recusandae sit voluptatem.</p>
    </div>
  </section>

  <section>
    <div class="device-select-counter">
      <div class="input-group">
        <select class="custom-select">
          <option>Choose...</option>
          <option value="1" selected>1</option>
          <option value="2">2</option>
          <option value="3">3</option>
        </select>
      </div>
    </div>
    <div class="device-select-btn">
      <div ngbDropdown class="d-inline-block">
        <button class="btn btn-outline-primary" id="dropdownBasic1" ngbDropdownToggle>Ta bord</button>
        <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
          <button ngbDropdownItem>Action - 1</button>
          <button ngbDropdownItem>Another Action</button>
          <button ngbDropdownItem>Something else is here</button>
        </div>
      </div>
    </div>
  </section>

  <section>
    <div class="form-img">
      <mat-icon aria-hidden="false">airport_shuttle</mat-icon>
    </div>
    <input class="form-control" type="text" placeholder="Lorem ipsum dolor sit amet.">
  </section>

  <section>
    <div class="insurance">
      <div class="info">
        <span>Insurance</span>
        <mat-icon aria-hidden="false">info</mat-icon>
      </div>

      <button class="btn btn-outline-primary" (click)="insuranceList = !insuranceList">Other</button>
    </div>
  </section>

  <div *ngIf="insuranceList" class="insurance-list">
    <p>Forsakring Sure Thing</p>

    <div class="list-group">
      <div class="item">
        <h5 class="item-title">Mobilabonnemang Telefon</h5>

        <div class="item-line">
          <span>APPLE iPhone X 64 GB minne, Silver</span> <small>8700 kr</small>
        </div>
        <div class="item-line">
          <span>Pay now</span> <small>3500 kr</small>
        </div>
        <div class="item-line">
          <span> 1-24 Months </span> <small>220 kr/man</small>
        </div>
      </div>

      <div class="item">
        <h5 class="item-title">Abonnemang</h5>
        <div class="item-line">
          <span>Telia Mobil 30 GB 24 Månader</span> <small>350 kr/man</small>
        </div>
      </div>

      <div class="item">
        <h5 class="item-title">Tillägg</h5>
        <div class="item-line">
          <span>Extra sim-card +69kr/man</span> <small>+69 kr/man</small>
        </div>
      </div>

      <div class="item">
        <h5 class="item-title">Att betala</h5>
        <div class="item-line">
          <span>Engångskostnad</span> <small>3500 kr</small>
        </div>
        <div class="item-line">
          <span>Total månadskostnad</span> <small>668 kr /mån</small>
        </div>
        <div class="item-line">
          <span>Minsta totalkostnad</span> <small>7889 kr</small>
        </div>
      </div>

      <div class="item">
        <h5 class="item-title">Telefon & sim-kort levereras separat</h5>
        <div class="item-line">
          <span>
            Vid köp av telefon & tillhörande abonnemang levereras paketen till
            ditt närmsta ombud. Telefonen och sim-kort delas upp i två leveranser
            och kan ha varierande leveranstid.
          </span>
        </div>
      </div>

    </div>
  </div>
</div>
