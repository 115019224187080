import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { ProviderModel } from '../models';
import {Campaign} from '../../views/pages/contract-flow/campaigns/models/campaign';

@Injectable({ providedIn: 'root' })
export class ProviderService {
  private readonly API_URL = environment.api_url;
  private readonly API_PROVIDER_URL = `${this.API_URL}/providers`;
  constructor(
    private http: HttpClient,
  ) {
  }

  getProvidersList(): Observable<ProviderModel[]> {
    return this.http.get<any[]>(this.API_PROVIDER_URL);
  }

  getPersonalInformation(personalNumber: string): Observable<any> {
    return this.http.get(`${this.API_URL}/user/personalnumber/data/${personalNumber}`);
  }

  getBankId(personNumber: string, providerId: number): Observable<any> {
    const requestData = {
      personalNumber: personNumber,
      providerId
    };

    return this.http.post<object>(`${this.API_URL}/user/verify`, requestData);
  }

  getActiveCampaigns(contractId: number, deviceId: number, minDeviceValue?: number): Observable<Array<Partial<Campaign>>> {
    const body = {
      contractIds: [contractId],
      deviceIds: deviceId ? [deviceId] : [],
      minDeviceValue,
    };
    return this.http.post<Array<Partial<Campaign>>>(`${this.API_URL}/campaigns/criterias`, body);
  }

  checkBankId(transactionId: string, providerId: number): Observable<any> {
    return this.http.get(`${this.API_URL}/user/verify/${transactionId}?providerId=${providerId}`);
  }

  initiateTeliaFinancePayment(orderIds: number[]): Observable<any> {
    return this.http.get(`${this.API_PROVIDER_URL}/telia/merchant/payment/initiate?orderIds=${orderIds}`);
  }
}
