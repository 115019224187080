import {DeviceModel} from './device.model';
import {ContractModel} from './contract.model';

export type OrderState = 'new' | 'under_review' | 'confirmed' | 'prepared' | 'delivered';

export class OrderModel {
  id: number = 0;
  state: {
    id: number;
    state: OrderState
  };
  deviceId: number = 0;
  device: DeviceModel = new DeviceModel();
  contractId: number = 0;
  contract: ContractModel = new ContractModel();
  subventionId: number = 0;
  subvention: number = 0;
  totalSubscriptionCostPerMonth: number = 0;
  phone: string = '';


  constructor(init?: Partial<OrderModel>) {
    Object.assign(this, init);
  }
}
