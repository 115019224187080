import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { CoreModule } from '../../core/core.module';
import { InlineSVGModule } from 'ng-inline-svg';
import { HeaderComponent } from './header/header.component';
import { BrandComponent } from './header/brand/brand.component';
import { HeaderMobileComponent } from './header/header-mobile/header-mobile.component';
import { MenuHorizontalComponent } from './header/menu-horizontal/menu-horizontal.component';
import { FooterComponent } from './footer/footer.component';

import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatIconModule } from '@angular/material/icon';
import { MatBadgeModule } from '@angular/material/badge';
import { MatDialogModule } from '@angular/material/dialog';

import {
  LanguageSelectorComponent, ScrollTopComponent,
  SearchDefaultComponent, SearchDropdownComponent, SearchResultComponent, SplashScreenComponent,
} from './layout';
import { BreadcrumbComponent } from './layout/topbar/breadcrumb/breadcrumb.component';
import { CardDevicesComponent } from './layout/card-devices/card-devices.component';
import { UseDiscountCodeComponent } from './layout/use-discount-code/use-discount-code.component';
import { ModifyPhoneComponent } from './layout/modify-phone/modify-phone.component';
import { SearchPhoneComponent } from './layout/search-phone/search-phone.component';
import { TranslateModule } from '@ngx-translate/core';
import { CartRedirectionComponent } from './layout/cart-redirection/cart-redirection.component';
import {NgxSliderModule} from '@angular-slider/ngx-slider';
import {ToasterComponent} from "./toaster/toaster.component";
import {ToastComponent} from "./toaster/toast/toast.component";


@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        FormsModule,
        ReactiveFormsModule,
        NgbModule,
        PerfectScrollbarModule,
        InlineSVGModule,
        CoreModule,
        TranslateModule.forChild(),
        MatProgressSpinnerModule, MatIconModule, MatBadgeModule,
        NgxSliderModule ,
        MatDialogModule,
    ],
    declarations: [
        ScrollTopComponent,
        SearchResultComponent,
        SplashScreenComponent,
        LanguageSelectorComponent,
        SearchDefaultComponent,
        SearchDropdownComponent,
        BreadcrumbComponent,
        CardDevicesComponent,
        UseDiscountCodeComponent,
        ModifyPhoneComponent,
        SearchPhoneComponent,
        HeaderComponent, BrandComponent, HeaderMobileComponent, MenuHorizontalComponent,
        FooterComponent,
        CartRedirectionComponent,
        ToasterComponent,
        ToastComponent,
    ],
    exports: [
        ScrollTopComponent,
        SearchResultComponent,
        SplashScreenComponent,
        LanguageSelectorComponent,
        SearchDefaultComponent,
        SearchDropdownComponent,
        BreadcrumbComponent,
        CardDevicesComponent,
        UseDiscountCodeComponent,
        ModifyPhoneComponent,
        SearchPhoneComponent,
        CartRedirectionComponent,
        HeaderComponent, BrandComponent, HeaderMobileComponent, MenuHorizontalComponent,
        FooterComponent,
        ToasterComponent,
    ],
    providers: []
})
export class PartialsModule {
}
