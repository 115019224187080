import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'kt-card-devices',
  templateUrl: './card-devices.component.html',
  styleUrls: ['./card-devices.component.scss']
})
export class CardDevicesComponent implements OnInit {
  loaderDeviceIcon: string;
  insuranceList = false;

  constructor() {
  }

  ngOnInit() {
    this.loaderDeviceIcon = './assets/media/products/product1.png';
  }

}
