import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { catchError, debounceTime, distinctUntilChanged, switchMap, takeUntil, tap, map } from 'rxjs/operators';
import { of, Subject } from 'rxjs';
import { DeviceService } from 'src/app/core/services';
import { AppState } from 'src/app/core/reducers';
import { Store } from '@ngrx/store';
import { ContractReducerState } from 'src/app/core/contract';
import { DeviceModel } from 'src/app/core/models';
import { Router } from '@angular/router';

@Component({
  selector: 'kt-search-phone',
  templateUrl: './search-phone.component.html',
  styleUrls: ['./search-phone.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchPhoneComponent implements OnInit, OnDestroy {

  private destroyStream$ = new Subject<void>();
  searchForm: FormGroup;
  loading = false;
  OS = ['iPhone', 'Android', 'other'];
  selectOS: string = null;
  resultSearch: any[];
  headquarterId: null;

  constructor(
    private _fb: FormBuilder,
    private cdr: ChangeDetectorRef,
    public dialogRef: MatDialogRef<SearchPhoneComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private deviceService: DeviceService,
    private store: Store<AppState>,
    private router: Router,
  ) {
  }

  ngOnInit() {
    this.createUserForm();
    this.store
      .select('ContractStore')
      .pipe(
        takeUntil(this.destroyStream$),
        map((state: ContractReducerState) => state.selectDevices)
      )
      .subscribe((device: DeviceModel) => {
        this.headquarterId = device.headquarterId;
      });
  }
  ngOnDestroy() {
    this.destroyStream$.next();
  }
  createUserForm() {
    this.searchForm = this._fb.group({
      name: ['', Validators.required]
    });

    this.searchForm.get('name').valueChanges
      .pipe(
        debounceTime(300),
        distinctUntilChanged(),
        tap(() => this.loading = true),
        switchMap((value: string) => {
          const hed = `&headquarterId=1`;
          const query = value + hed;
          return this.deviceService.autocompleteDevices(query)
            .pipe(
              takeUntil(this.destroyStream$),
              catchError(() => of([]))
            );
        }),
        tap(() => this.loading = false),
      )
      .subscribe((res: any[]) => {
        // this.resultSearch = res.map( (item) => new DeviceModel(item) );
        this.resultSearch = res;

        if (this.searchForm.get('name').invalid) {
          this.resultSearch = [];
        }

        this.cdr.detectChanges();
      });
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  selectDevice(device) {
    this.dialogRef.close('redirect');
    this.router.navigate([`/sku/${device.articleNumber}`]);
  }

}
